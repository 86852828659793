.nav {
  width: 100%;
  position: fixed;
  z-index: 3000;
  background: transparent;
  font-size: 16px;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;

  .container { position: relative }
  > * { vertical-align: middle }

  &__link {
    position: relative;
    color: $black;
    @include transition($transition-base);

    .evening &,
    .night & { color: $white; }

    &:hover {
      color: $black;
      text-decoration: none;
      border-bottom: 2px solid $gray-800;

      .evening &,
      .night & {
        color: $white;
        border-bottom: 2px solid rgba(255,255,255,.7);
      }
    }
  }

  &__burger {
    position: absolute;
    display: none;
    left: $spacer;
    top: 5px;
    width: 22px;
    height: 14px;
    text-indent: -9000px;
    border-top: 2px solid $gray-900;
    border-bottom: 2px solid $gray-900;
    cursor: pointer;
    opacity: .7;

    &:hover { opacity: 1; }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 4px;
      width: 22px;
      height: 2px;
      background: $gray-900;
    }
  }

  &__main {
    float: right;
    padding: 10px 25px;
    margin-top: 20px;
    list-style: none;
    @include transition($transition-base);
    @include media-breakpoint-down(xs) { display: none; }


    li {
      display: inline-block; margin: 0; padding: 0;
      & + li { margin-left: 22px; }
    }
  }

  &_alt {

    .nav__main {
      margin-top: -60px;
    }

    &.nav_delta .nav__main {
      margin-top: 18px !important;
      background: rgba(0,0,0,.85);
      box-shadow: 0 3px 2px rgba(0,0,0,.1);
      border-radius: 40px;

      .nav__link { color: $white; }
    }


    @include media-breakpoint-down(xs) {
      padding: 22px 0;
      height: 68px;
    }
  }
}
