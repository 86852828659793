html, body { height: 100%; }
body {
  display: none;
  &.loaded { display: block; display: initial; }
}

p {
  margin-bottom: 1.15em;
}

img { max-width: 100%; }



// HERO
// ------------------------------------------
.hero {
  position: fixed;
  width: 100%; height: 100%; z-index: 100;
  display:flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background: $white url(../images/bg-noon.jpg) no-repeat bottom center;
  background-size: cover;
  overflow: hidden;

  .morning & { background: #F7F6F2 url(../images/bg-morning2.jpg) no-repeat bottom center; }
  .evening & { background: #342D28 url(../images/bg-evening.jpg) no-repeat bottom center; }
  .night & { background: #342D28 url(../images/bg-night.jpg) no-repeat bottom center; }

  &__wrapper { text-align: center; position: relative; }

  &__caption {
    opacity: .9;
    font-size: calc(1.2em + 12vw);
    font-family: Sacramento;

    .evening &,
    .night & { color: $white; }
  }

  &__intro {
    font-family: "Open sans", Arial;
    font-size: calc(.9rem + .4vw);
    margin: 1em auto 0 auto;
    line-height: 1.4em;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 86%;
    font-weight: 600;
    @include media-breakpoint-up(sm) { width: 60%; }
    @include media-breakpoint-up(lg) { width: 40%; }

    small {
      font-weight: inherit;
    }

    .evening &,
    .night & { color: $white;  }
  }

  p {
    max-width: 70%;
    margin-top:1em;
    text-align: center;
  }

  &__scroll {
    position: absolute;
    width: 20px;
    opacity: .5;
    bottom: -130px;
    @include media-breakpoint-down(sm) { display: none; }

    path {
      fill: #000;

      .evening &,
      .night & { fill: $white; }

    }
  }
}


// HELPERS
// -----------------------------------------

// intext divider
.divider {
  display: block;
  width: 6%;
  height: 1px;
  margin: $spacer * 2 0;
  border-bottom: 2px solid $gray-900;
}


// SOCIAL ICONS
// ------------------------------------------
.social {
  margin: 0;
  padding: 0;
  display: inline-block;

  & { margin-right: 10px; }
  * { vertical-align: middle; }

  a {
    color: $gray-500;
    text-decoration: none;
    &:hover {
      color: $gray-900;
      .ion {
        color: theme-color("primary");
        background: $white;
        transform: translate(0, -1px);
        box-shadow: 0 3px 6px rgba(0,0,0,.1);
      }
    }
  }

  .ion {
    font-size: 23px;
    color: $white;
    width: 40px;
    height: 40px;
    line-height: 39px;
    border-radius: 50%;
    background: theme-color("primary");
    border: 1px solid theme-color("primary");
    text-align: center;
    margin: 0;
    @include transition($transition-base);
  }
  span {
    display: none;
    font-size: .85rem;
  }
}


// ------------------------------------------
// MAIN PART & ITS SECTIONS
// ------------------------------------------

.main {
  z-index: 1000;
  background: transparent;
  position: relative;
}

.section {
  padding: $section-padding - 20px 0 $section-padding + 30px 0;
  background: $white;
  @include media-breakpoint-down(xs) {
    padding: $section-padding / 2 - 20px 0 $section-padding / 2 + 30px 0;
  }

  &.invert {
    background: $gray-900;
    color: $white;
  }

  &:last-child { padding-bottom: $section-padding; }

  &__caption {
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 2.5px;
    font-weight: 600;
    // opacity: .4;
    color: theme-color("primary");
    margin: 0;
  }

  &__subcaption {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 300;
    font-family: 'Montserrat', 'Open sans', Arial;
    width: 80%;
    margin: $spacer * 1.7 auto;
    @include media-breakpoint-down(xs) { line-height: 1.1em; }
  }

  &__intro {
    text-align: center;

    @include media-breakpoint-up(sm) {
      padding: 0 10vh;
    }
  }
}



// SECTION: ABOUT
.about {
  @include angle(before, true);

  &__specs {
    @include media-breakpoint-up(md) { width: 286px; max-width: 100%; }
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &__social {
    margin: 40px 0 0 0;
    padding: 0;
    > .social { margin-bottom: 15px; }
    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  &__what {
    font-family: "Open sans", Arial;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 400;

    @include media-breakpoint-up(lg) {
      font-size: 1.3rem;
    }

    span {
      display: block;
      margin-top: 10px;
    }
  }

  &__img {
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    border-radius: 10px;
    @include media-breakpoint-down(sm) {
      max-width: 45%;
      border-radius: 50%;
    }
  }
  &__who { font-weight: 300; font-size: 1.4em; line-height: 1.3;  }
  &__intro { padding-left: 3vw; }
  &__project {
    @include media-breakpoint-down(sm) {
      text-align: center;

      .divider {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}


// SECTION: SKILLS
.skills {
  @include angle(before, true);

  &__content {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    font-size: 14px;
  }

  li {
    + li {
      margin-top: 7px;
    }
  }

  legend {
    border-bottom: 1px solid $primary;
  }
}

// SECTION: WORKS
.works {
  background: $white;
  @include angle(before, true);

  &__list {
    margin: $spacer*5 0 0 0;
  }
}

.work {

  display: flex;
  // align-items: center;

  &.reorder {
    .work__texts {
      @include media-breakpoint-up(sm) { text-align: right; }
      @include media-breakpoint-up(lg) { padding: 1rem 0 0 7vh; }
    }

    .work__img {
      @include media-breakpoint-up(sm) { margin-right: 30px; order: 1; }
    }
  }

  &__texts {
    flex: 1 0 0;
    order: 2;
    @include media-breakpoint-up(lg) { padding: 1rem 7vh 0 0; }
  }

  &__img {
    flex: 1 0 0;
    order: 3;
    margin-left: 30px;

    @include media-breakpoint-down(xs) { display: none; }

    img { width: 100%; }
  }

  &__title {
    text-transform: uppercase;
    color: theme-color("primary");
  }

  &__services {
    color: $gray-400;
    font-size: .9rem;
  }

  &__text {
    margin: $spacer*2 0 0 0;
  }

  &__actions {
    margin: $spacer*2 0 $spacer 0;
  }

  &__btn-visit {
    @extend .btn-secondary;
  }



  & + .work {
    margin-top: $spacer * 4;
    position: relative;
    @include media-breakpoint-up(sm) { margin-top: $spacer * 6; }

    &:before { @include media-breakpoint-down(xs) {
      content: " ";
      display: block;
      position: absolute;
      background: theme-color("primary");
      width: 60px;
      height: 2px;
      top: -$spacer*2.5;
      left: 0;
    }}
  }
}


// SECTION: TESTIMONIALS
.testimonials {
  background: $gray-100;
  @include angle(before, true);
}

.testimonial {

  & + & {
    margin-top: 3rem;
    @include media-breakpoint-up(sm) {
      margin-top: 5rem;
    }

  }

  &__text-container {
    background: $white;
    font-size: .95rem;
    border-radius: 5px;
    @include angle(both, true);
    &:before,
    &:after { box-shadow: none; }
  }

  &__text {
    position: relative;
    font-style: italic;
    padding: 3rem 2rem 4rem 2rem;

    @include media-breakpoint-up(sm) {
      padding: 3rem 8rem 4rem 8rem;
    }

    &:before,
    &:after {
      display: none;
      content:'\201C';
      position: absolute;
      top: 5px;
      left: -15px;
      font-family: Georgia, "Times new roman", serif;
      color: $gray-300;
      font-size: 11rem;
      line-height: 1em;
      width: 10rem;
      text-align: center;

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    &:after {
      content:'\201D';
      left: auto;
      right: 20px;
      top: auto;
      bottom: -74px;
    }
  }

  &__avatar {
    position: relative;
    z-index: 1111;
    margin: -1rem auto 7px auto;
    width: 80px;
    height: 80px;
    border-radius: 50%; overflow:hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__name {
    text-align: center;
    margin: 0;
  }

  &__info {
    text-align: center;
    font-weight: bold;
    font-size: .9rem;
    margin: 0;
  }

}



// SECTION: CONTACT
// ------------------------------------------
.contact {
  @include angle(before, true);
}

.form-group {
  margin-bottom: $spacer;
}
.form-control {
  border: 4px solid #e3e3e3;
  padding: 7px;
  width: 100%;
  padding-left: 10px;
  @include media-breakpoint-down(xs) { margin-bottom: $spacer; }
}



// FOOTER
// -------------------------------------------

.footer {
  @include angle(before, true);
  z-index: 1100;
  position: relative;
  background: $gray-100;
  font-size: .9rem;
  padding-top: $spacer * .7;
  padding-bottom: $spacer * 2.7;

  &__copy {
    width: 100%;
    text-align: center;
  }
}
